import type { FlexProps, LinkProps } from "@chakra-ui/react"
import {
  Spinner,
  Container,
  IconButton,
  HStack,
  Stack,
  Text,
  useDisclosure,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Flex,
  Button,
  Divider,
} from "@chakra-ui/react"
import type { FC } from "react"
import React, { useState, useRef, useEffect } from "react"
import { useAccount } from "wagmi"

import type { OrganizationMenu } from "ui/layouts/main/header/HeaderDAO"
import {
  HeaderTopLeftDAO,
  HeaderLinksDAO,
  HeaderTopRightDAO,
} from "ui/layouts/main/header/HeaderDAO"
import {
  HeaderTopLeftCommon,
  HeaderLinksCommon,
  HeaderTopRightCommon,
} from "ui/layouts/main/header/HeaderCommon"
import { shortenIfAddress } from "web3/helpers/address"
import SearchDaosModal from "common/components/SearchDaosModal"
import Link from "common/components/Link"
import HeaderTopBorder from "ui/components/header/HeaderTopBorder"
import { EXTERNAL_ROUTES, ROUTES } from "common/constants/routes"
import ConnectAccountButton, {
  useConnectAccount,
} from "web3/components/ConnectAccountButton"
import WalletSelectionModal from "web3/components/WalletSelectionModal"
import { useDevice } from "common/hooks/useDevice"
import SearchDaosDrawer from "common/components/SearchDaosDrawer"
import { X } from "ui/components/icons/font-awesome/X"
import ClientOnly from "common/components/ClientOnly"
import { User } from "ui/components/icons/font-awesome/User"
import { Gear } from "ui/components/icons/font-awesome/Gear"
import { RightFromBracket } from "ui/components/icons/font-awesome/RightFromBracket"
import { House } from "ui/components/icons/font-awesome/House"
import { useSession } from "session/providers/SessionProvider"
import { useLogout } from "session/hooks/useLogout"
import { AwaitRender } from "common/components/AwaitRender"
import Safe from "ui/components/icons/Safe"
import type { AccountId, Organization } from "query/graphql"
import { useAddressSafesQuery } from "query/graphql"
import { useMe } from "user/providers/MeProvider"
import { getAccountAddress } from "web3/helpers/accountId"
import { useLoginAsSafe } from "session/hooks/useLoginAsSafe"
import { useSiwe } from "web3/hooks/useSiwe"
import { Wallet } from "ui/components/icons/font-awesome/Wallet"
import { useConnectorIcon } from "web3/hooks/useConnectorIcon"
import { shortString } from "common/helpers/string"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { useSignerStore } from "web3/providers/SignerProvider"
import { Bell } from "ui/components/icons/font-awesome/Bell"
import GenericNetworkIcon from "web3/components/icons/GenericChainIcon"
import { findChainBySafeId } from "web3/helpers/chains"
import HeaderAccountMenu from "ui/components/header/HeaderAccountMenu"
import UserMenuDetails from "user/components/UserMenuDetails"
import { Bolt } from "ui/components/icons/font-awesome/Bolt"

enum LayoutType {
  DAO = "DAO",
  Common = "Common",
}

const MainLayoutHeader: FC<
  {
    displayDashboardButton?: boolean
    activeOrganizationMenu?: OrganizationMenu
    organization?: Organization
    redirectTo?: {
      login?: string
      logout?: string
      disconnect?: string
    }
    isWhiteLabel?: boolean
    displayConnectButtons?: boolean
  } & FlexProps
> = ({
  displayDashboardButton = false,
  activeOrganizationMenu = undefined,
  organization = undefined,
  isWhiteLabel = false,
  redirectTo = {},
  displayConnectButtons = true,
  ...flexProps
}) => {
  const searchDaosToastDisclousure = useDisclosure()
  const searchDaosDrawerDisclousure = useDisclosure()
  const tallyMenuDisclousure = useDisclosure()
  const userMenuDisclousure = useDisclosure()
  const walletSelectionDisclousure = useDisclosure()
  const searchModalRef = useRef(null)
  const searchDrawerRef = useRef(null)
  const tallyMenuRef = useRef(null)
  const userMenuRef = useRef(null)
  const { onLargeDevice, onLittleDevice } = useDevice()

  const [isRenderReady, setIsRenderReady] = useState(false)

  // To avoid Hydration error
  useEffect(() => {
    setIsRenderReady(true)
  }, [])

  if (!isRenderReady) {
    return null
  }

  const layoutType = organization ? LayoutType.DAO : LayoutType.Common

  return (
    <AwaitRender
      loader={
        <Flex
          align="center"
          as="nav"
          bg="white"
          borderBottom="1px solid hsla(220, 17%, 93%, 1)"
          h={20}
          justify="space-between"
          w="full"
          {...flexProps}
        >
          <Container
            display="flex"
            justifyContent="space-between"
            maxW="6xl"
            px={{ base: 4, lg: 0 }}
          >
            <Stack align="center" direction="row" spacing={4}>
              {layoutType === LayoutType.DAO && organization ? (
                <HeaderTopLeftDAO
                  isWhiteLabel={isWhiteLabel}
                  organization={organization}
                />
              ) : null}

              {layoutType === LayoutType.Common ? (
                <HeaderTopLeftCommon />
              ) : null}
            </Stack>
          </Container>
        </Flex>
      }
      ms={200}
    >
      <HeaderTopBorder />
      <Flex
        align="center"
        as="nav"
        bg="white"
        borderBottom="1px solid hsla(220, 17%, 93%, 1)"
        h={20}
        justify="space-between"
        w="full"
        {...flexProps}
      >
        <Container
          display="flex"
          justifyContent="space-between"
          maxW="6xl"
          px={{ base: 4, lg: 0 }}
        >
          <Stack align="center" direction="row" spacing={4}>
            {layoutType === LayoutType.DAO && organization ? (
              <HeaderTopLeftDAO
                isWhiteLabel={isWhiteLabel}
                organization={organization}
                searchDaosToastDisclousure={searchDaosToastDisclousure}
                tallyMenuDisclousure={tallyMenuDisclousure}
                tallyMenuRef={tallyMenuRef}
              />
            ) : null}

            {layoutType === LayoutType.Common ? (
              <HeaderTopLeftCommon
                includeSearchButton
                searchDaosToastDisclousure={searchDaosToastDisclousure}
                searchModalRef={searchModalRef}
              />
            ) : null}
          </Stack>
          <Stack
            align="center"
            direction="row"
            display={onLargeDevice}
            spacing={4}
          >
            {layoutType === LayoutType.DAO && organization ? (
              <HeaderLinksDAO
                activeMenu={activeOrganizationMenu}
                isWhiteLabel={isWhiteLabel}
                organization={organization}
              />
            ) : null}

            {layoutType === LayoutType.Common ? <HeaderLinksCommon /> : null}

            {displayDashboardButton ? (
              <Link
                data-qa="homeheader-pricing-link"
                href={ROUTES.user.yourDAOs()}
              >
                <Button data-qa="homeheader-pricing-button" variant="primary">
                  Dashboard
                </Button>
              </Link>
            ) : null}

            {displayConnectButtons ? (
              <Stack align="center" direction="row" spacing={2}>
                <ClientOnly>
                  <HeaderAccountMenu
                    isWhiteLabel={isWhiteLabel}
                    organization={organization}
                    redirectTo={redirectTo}
                  />
                </ClientOnly>
              </Stack>
            ) : null}
          </Stack>
          <HStack align="center" display={onLittleDevice} spacing={2}>
            {layoutType === LayoutType.DAO && organization ? (
              <HeaderTopRightDAO
                activeMenu={activeOrganizationMenu}
                isWhiteLabel={isWhiteLabel}
                organization={organization}
                userMenuDisclousure={userMenuDisclousure}
                userMenuRef={userMenuRef}
              />
            ) : null}

            {layoutType === LayoutType.Common ? (
              <HeaderTopRightCommon
                searchDaosDrawerDisclousure={searchDaosDrawerDisclousure}
                searchDrawerRef={searchDrawerRef}
                tallyMenuDisclousure={tallyMenuDisclousure}
                tallyMenuRef={tallyMenuRef}
                userMenuDisclousure={userMenuDisclousure}
                userMenuRef={userMenuRef}
              />
            ) : null}
          </HStack>
        </Container>
      </Flex>
      <WalletSelectionModal
        isOpen={walletSelectionDisclousure.isOpen}
        onClose={() => walletSelectionDisclousure.onClose()}
      />
      <SearchDaosModal
        finalRef={searchModalRef}
        isOpen={searchDaosToastDisclousure.isOpen}
        onClose={() => searchDaosToastDisclousure.onClose()}
      />
      <SearchDaosDrawer
        finalRef={searchDrawerRef}
        isOpen={searchDaosDrawerDisclousure.isOpen}
        onClose={() => searchDaosDrawerDisclousure.onClose()}
      />

      <TallyMenuDrawer
        finalRef={tallyMenuRef}
        isOpen={tallyMenuDisclousure.isOpen}
        onClose={() => tallyMenuDisclousure.onClose()}
      />
      <UserMenuDrawer
        finalRef={userMenuRef}
        isOpen={userMenuDisclousure.isOpen}
        isWhiteLabel={isWhiteLabel}
        organization={organization}
        redirectTo={redirectTo}
        onClose={() => userMenuDisclousure.onClose()}
        onOpen={() => {
          userMenuDisclousure.onClose()
          walletSelectionDisclousure.onOpen()
        }}
      />
    </AwaitRender>
  )
}

export default MainLayoutHeader

function UserLinks({
  account,
  organization,
  isWhiteLabel,
  accountId,
  ...linkProps
}: {
  organization?: Organization
  isWhiteLabel: boolean
  account?: string
  accountId?: string
} & LinkProps) {
  return (
    <>
      {isWhiteLabel && organization ? (
        <Link
          className="no-underline"
          color="neutral.600"
          fontSize="md"
          fontWeight="medium"
          href={getWhiteLabelRoute(
            ROUTES.governance.delegate.profile(
              organization.slug,
              account as unknown as string,
            ),
          )}
          lineHeight="24px"
          {...linkProps}
        >
          <Stack isInline align="center" spacing={2}>
            <Icon as={User} h={4} w={4} />
            <Text>Delegate profile</Text>
          </Stack>
        </Link>
      ) : (
        <>
          {organization ? (
            <>
              {organization.governorIds.length > 0 ? (
                <Link
                  className="no-underline"
                  color="neutral.600"
                  fontSize="md"
                  fontWeight="medium"
                  href={ROUTES.governance.myVotingPower.index(
                    organization.slug,
                  )}
                  lineHeight="24px"
                  {...linkProps}
                >
                  <Stack isInline align="center" spacing={2}>
                    <Icon as={Bolt} h={4} w={4} />
                    <Text>My voting power</Text>
                  </Stack>
                </Link>
              ) : null}
              <Link
                className="no-underline"
                color="neutral.600"
                fontSize="md"
                fontWeight="medium"
                href={ROUTES.governance.delegate.profile(
                  organization.slug,
                  account ?? "",
                )}
                lineHeight="24px"
                {...linkProps}
              >
                <Stack isInline align="center" spacing={2}>
                  <Icon as={User} h={4} w={4} />
                  <Text>My delegate profile</Text>
                </Stack>
              </Link>
              <Divider />
            </>
          ) : null}

          <Link
            className="no-underline"
            color="neutral.600"
            fontSize="md"
            fontWeight="medium"
            href={ROUTES.user.yourDAOs()}
            lineHeight="24px"
            {...linkProps}
          >
            <Stack isInline align="center" spacing={2}>
              <Icon as={House} h={4} w={4} />
              <Text>Your DAOs</Text>
            </Stack>
          </Link>
          {account ? (
            <Link
              className="no-underline"
              color="neutral.600"
              fontSize="md"
              fontWeight="medium"
              href={ROUTES.profile(account)}
              lineHeight="24px"
              {...linkProps}
            >
              <Stack isInline align="center" spacing={2}>
                <Icon as={User} h={4} w={4} />
                <Text>Profile</Text>
              </Stack>
            </Link>
          ) : null}
          {!isWhiteLabel ? (
            <Link
              isExternal
              className="no-underline"
              color="neutral.600"
              fontSize="md"
              fontWeight="medium"
              href={EXTERNAL_ROUTES.notifications.index(accountId)}
              lineHeight="24px"
              {...linkProps}
            >
              <Stack isInline align="center" spacing={2}>
                <Icon as={Bell} h={4} w={4} />
                <Text>Notifications</Text>
              </Stack>
            </Link>
          ) : null}
        </>
      )}
      <Link
        className="no-underline"
        color="neutral.600"
        fontSize="md"
        fontWeight="medium"
        href={ROUTES.user.settings()}
        lineHeight="24px"
        {...linkProps}
      >
        <Stack isInline align="center" spacing={2}>
          <Icon as={Gear} h={4} w={4} />
          <Text>Settings</Text>
        </Stack>
      </Link>
      <SignInAsSafe isWhiteLabel={isWhiteLabel} organization={organization} />
    </>
  )
}

function TallyMenuDrawer({
  finalRef,
  isOpen,
  onClose,
}: {
  finalRef: React.MutableRefObject<null>
  isOpen: boolean
  onClose: () => void
}) {
  return (
    <>
      <Drawer
        finalFocusRef={finalRef}
        isOpen={isOpen}
        placement="right"
        size="full"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent height="100% !important!">
          <DrawerHeader p={4}>
            <Flex align="center" direction="row" justify="flex-end" w="full">
              <IconButton
                _focusVisible={{
                  boxShadow: "none",
                  borderColor: "transparent",
                  outline: "3px solid hsla(216, 12%, 84%, 1)",
                  outlineOffset: "2px",
                }}
                aria-label="Close menu"
                bg="white"
                icon={<Icon as={X} fill="neutral.500" h={4} w={4} />}
                size="sm"
                onClick={onClose}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody
            borderTop="1px solid hsla(220, 17%, 93%, 1)"
            overflowY="auto"
            p={2}
            tabIndex={-1}
          >
            <HeaderLinksCommon />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

function UserMenuDrawer({
  finalRef,
  isOpen,
  onClose,
  onOpen,
  isWhiteLabel,
  redirectTo,
  organization,
}: {
  finalRef: React.MutableRefObject<null>
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  isWhiteLabel: boolean
  redirectTo?: {
    login?: string
    logout?: string
    disconnect?: string
  }
  organization?: Organization
}) {
  const { isLoggedIn } = useSession()
  const { address } = useAccount()
  const me = useMe()
  const { logout } = useLogout()

  return (
    <>
      <Drawer
        finalFocusRef={finalRef}
        isOpen={isOpen}
        placement="right"
        size="full"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent height="100% !important!">
          <DrawerHeader p={4}>
            <Flex align="center" direction="row" justify="flex-end" w="full">
              <IconButton
                _focusVisible={{
                  boxShadow: "none",
                  borderColor: "transparent",
                  outline: "3px solid hsla(216, 12%, 84%, 1)",
                  outlineOffset: "2px",
                }}
                aria-label="Close menu"
                bg="white"
                icon={<Icon as={X} fill="neutral.500" h={4} w={4} />}
                size="sm"
                onClick={onClose}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody
            borderTop="1px solid hsla(220, 17%, 93%, 1)"
            overflowY="auto"
            p={2}
            tabIndex={-1}
          >
            {address ? (
              <UserMenuDetails
                redirectTo={redirectTo?.logout}
                walletAddress={address}
              />
            ) : (
              <Stack pt={8}>
                <ConnectAccountButton
                  variant="primary"
                  w="full"
                  onOpen={onOpen}
                />
              </Stack>
            )}

            {address ? (
              <Stack align="stretch" direction="column" spacing={2} w="full">
                <Divider mb={1} mt={2} />
                <UserLinks
                  _hover={{
                    background: "neutral.100",
                  }}
                  account={address}
                  accountId={me?.id}
                  isWhiteLabel={isWhiteLabel}
                  organization={organization}
                  px={4}
                  py={2}
                  textAlign="start"
                />

                <Divider mb={1} />

                {isLoggedIn ? (
                  <>
                    <Button
                      _focus={{
                        boxShadow: "none",
                        borderColor: "transparent",
                        outline: "3px solid hsla(216, 12%, 84%, 1)",
                        outlineOffset: "2px",
                      }}
                      _hover={{
                        background: "neutral.100",
                      }}
                      bg="white"
                      border="transparent.1"
                      color="neutral.600"
                      fontSize="md"
                      fontWeight="medium"
                      lineHeight="24px"
                      textAlign="start"
                      w="full"
                      onClick={() => logout(redirectTo?.logout)}
                    >
                      <Stack
                        isInline
                        align="center"
                        justify="flex-start"
                        spacing={2}
                        w="full"
                      >
                        <Icon as={RightFromBracket} h={4} w={4} />
                        <Text>Logout</Text>
                      </Stack>
                    </Button>
                  </>
                ) : (
                  <ConnectAccountButton
                    variant="primary"
                    w="full"
                    onOpen={onOpen}
                  />
                )}
              </Stack>
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

function OwnedSafes() {
  const me = useMe()

  const accountId = me?.id ?? undefined

  const { data, isLoading } = useAddressSafesQuery(
    { accountId: accountId ?? "" },
    { enabled: Boolean(accountId) },
  )
  const { loginAsSafe } = useLoginAsSafe()

  const connectAccount = useConnectAccount({
    shouldPromptSiwe: true,
  })

  const handleLoginAsSafe = (safe: AccountId) => {
    loginAsSafe(safe)
  }

  if (me?.type === "SAFE") return null

  if (!accountId) {
    return (
      <Button
        backgroundColor="white"
        className="no-underline"
        color="neutral.600"
        fontSize="md"
        fontWeight="medium"
        h={10}
        w="full"
        onClick={(event) => {
          event.stopPropagation()

          connectAccount()
        }}
      >
        <Stack
          align="center"
          direction="row"
          justify="left"
          spacing={2}
          width="100%"
        >
          <Icon as={Safe} h={4} w={4} />
          <Text>Sign in as Safe</Text>
        </Stack>
      </Button>
    )
  }

  if (isLoading)
    return (
      <Stack>
        <Stack isInline align="center" spacing={3} w="full">
          <Spinner />
          <Text color="gray.600" textStyle="body.bold.sm">
            This may take a while
          </Text>
        </Stack>
      </Stack>
    )

  if (!data || !data.account.safes)
    return (
      <Stack ml={5}>
        <Stack isInline align="center" spacing={3} w="full">
          <Text color="gray.600" textStyle="body.bold.sm">
            No Safes found
          </Text>
        </Stack>
      </Stack>
    )

  const { safes } = data?.account

  return (
    <Stack>
      {safes?.map((safe, index) => {
        const safeAddress = getAccountAddress(safe)
        const chain = findChainBySafeId(safe)
        const networkIcon = chain ? GenericNetworkIcon(chain) : undefined

        return (
          <Button
            key={`safe-${index}`}
            backgroundColor="white"
            h={10}
            onClick={() => handleLoginAsSafe(safe)}
          >
            <Stack isInline align="center" justify="left" spacing={3} w="full">
              {!!networkIcon ? (
                <Icon as={networkIcon} h={5} w={5} />
              ) : (
                <Icon as={Safe} h={5} w={5} />
              )}
              <Text color="gray.600" textStyle="body.bold.md">
                {shortenIfAddress(safeAddress)}
              </Text>
            </Stack>
          </Button>
        )
      })}
    </Stack>
  )
}

function SignInAsSafe({
  isWhiteLabel,
  organization,
}: {
  isWhiteLabel: boolean
  organization?: Organization
}) {
  const me = useMe()
  const { signer, status } = useSignerStore()
  const { address } = useAccount()
  const { signInWithEthereum } = useSiwe()
  const connectorIcon = useConnectorIcon()

  return (
    <Stack>
      {me?.type === "EOA" ? (
        <Button
          as={Button}
          backgroundColor="white"
          className="no-underline"
          color="neutral.600"
          fontSize="16px"
          fontWeight="medium"
          justifyContent="start"
          lineHeight="24px"
          textAlign="left"
        >
          <Stack isInline align="center" spacing={2}>
            <Icon as={Safe} h={4} w={4} />
            <Text>Sign in as Safe</Text>
          </Stack>
        </Button>
      ) : null}
      {me?.type === "SAFE" && address && status === "success" && signer ? (
        <Button
          as={Button}
          backgroundColor="white"
          className="no-underline"
          justifyContent="start"
          textAlign="left"
          onClick={() =>
            signInWithEthereum({
              signer,
              address,
              redirectTo:
                isWhiteLabel && organization
                  ? getWhiteLabelRoute(ROUTES.governance.id(organization.slug))
                  : ROUTES.user.yourDAOs(),
            })
          }
        >
          <Stack isInline align="center" w="full">
            <Icon as={Wallet} h={4} w={4} />
            <Stack align="center" direction="row">
              <Text
                _hover={{ bgColor: "gray.100", color: "black" }}
                align="center"
                bgColor="auto"
                color="gray.700"
                fontSize="md"
                fontWeight="medium"
                py={2}
                width="100%"
              >
                Switch to
              </Text>
              <Stack align="center" direction="row" rounded="md">
                <Icon as={connectorIcon} color="gray.600" h={4} w={4} />
                <Text
                  color="neutral.600"
                  fontSize="md"
                  fontWeight="medium"
                  lineHeight="24px"
                >
                  {shortString(address)}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Button>
      ) : null}
      <OwnedSafes />
    </Stack>
  )
}
